import React from 'react';
import { EventConstants } from '../../../core/objects/constants';
import { ModalOpenData, ModalResponse } from '../../../core/objects/interfaces';
import { AsideService, EventService } from '../../services';
import { withTranslation } from 'react-i18next';
import { AsideHeader } from './aside-header';

import './aside.css';

class AsideClass extends React.Component {

    public state: any = {
        aside: null
    }

    componentDidMount() {
        EventService.subscribe(EventConstants.aside.open, (e: any) => this.handleAsideOpen(e));
        EventService.subscribe(EventConstants.aside.closeAll, (e: any) => this.closeAside(undefined, true));
    }

    render() {
        const { t }: any = this.props;
        return (
            this.state.aside ?
                <div className="pk-aside-container" id="pk-aside-container">
                    <div className="pk-aside-window bg-primary-50 rounded-xl shadow">
                        <AsideHeader title={'title'} onClose={(e: any) => this.handleAsideClose()} />
                        <div className="" style={{ display: 'flex', flexGrow: 1, overflow: 'overlay' }}>
                            {this.state.aside.component}
                        </div>
                    </div>
                </div>
                :
                <></>
        )
    }

    private handleAsideOpen(asideOpenData: ModalOpenData) {
        const clonedComponent = React.createElement(asideOpenData.component, asideOpenData.params);
        if (this.state.aside) {
            this.closeAside();
            setTimeout(() => {
                this.setState({
                    aside: {
                        component: clonedComponent,
                        params: asideOpenData.params,
                        name: asideOpenData.component.name
                    }
                });
            });
        } else {
            this.setState({
                aside: {
                    component: clonedComponent,
                    params: asideOpenData.params,
                    name: asideOpenData.component.name
                }
            });
        }
    }

    private handleAsideClose() {
        this.closeAside();
    }

    private closeAside(response: ModalResponse = { status: 'close', data: undefined }, forced: boolean = false) {
        AsideService.close(response);
        document.getElementById('pk-aside-container')?.classList.add('closing');
        if (forced) {
            this.setState({ aside: null });
        } else {
            setTimeout(() => {
                document.getElementById('pk-aside-container')?.classList.remove('closing');
                this.setState({ aside: null });
            }, 200);
        }

    }
}

export const Aside = withTranslation('translation')(AsideClass);