import { useEffect } from "react";
import { PakkaInput } from "shared/components/pk-input/pk-input.component";
import { ModalFactory } from "shared/services/modal/modal.factory";

export function ConfigModal() {

    useEffect(() => {
        // loadArea();
    }, []);
    return (
        <div className="flex flex-col">
            <PakkaInput className="w-full mb-4"
                model={{seed: ''}}
                name="seed"
            />
            <button className="bg-secondary-200 px-2 py-1 rounded" onClick={e => ModalFactory.config()}>Mas config</button>
        </div>
    )
}