import { Crud } from "./crud";

export class AreaResource extends Crud {

    constructor() {
        super('areas');
    }

    public generateMap(areaId: string) {
        return this.get(`${areaId}/generateMap`);
    }
}