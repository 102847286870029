import { EventConstants } from "../../../core/objects/constants/event.constant";
import { ApiClient } from "../api/api-client";
import { EventService } from "../event/event.service";

export class AreaService {

    public static areas: any[] = [];

    constructor() {
        EventService.subscribe(EventConstants.user.loginChanged, this.handleUserLogIn);
    }

    public static scanLatLng({lat, lng}: {lat: number, lng: number}) {
        ApiClient.area.get('', {params: {lat, lng, radius: 2000}})
        .then(res => {
            if (!res || !res.data) return;
            const addedAreas: any[] = []
            res.data.forEach((area: any) => {
                const duplicated = this.areas.find(a => a._id === area._id);
                if (!duplicated) {
                    addedAreas.push(area);
                    this.areas.push(area);
                }
            });
            // TODO: Añadir las áreas al objeto de la clase y detectar las repetidas
            EventService.publish(EventConstants.map.addAreas, addedAreas);
        })
    }
    
    private handleUserLogIn() {
        
    }
}