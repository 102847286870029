import { toast, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

export function Toast() {

    return (
        <>
            <ToastContainer enableMultiContainer
                containerId="1"
                position={toast.POSITION.BOTTOM_LEFT}

            />
            <ToastContainer enableMultiContainer
                containerId="2"
                draggable={false}
                position={toast.POSITION.BOTTOM_RIGHT}
            />
        </>
    )
}
