import { Link } from '@reach/router';
import { useEffect, useState } from 'react';
// import GoogleLogin from 'react-google-login';

import { User } from './user';
import { EventService, StorageService } from '../../services';
import { EventConstants } from '../../../core/objects/constants';
import { PakkaDropdown } from '../pk-dropdown/pk-dropdown';
import { ModalFactory } from 'shared/services/modal/modal.factory';

export function Navbar() {

    const [userLogged, setUserLogged] = useState(false);

    useEffect(() => {
        const token = StorageService.get('token');
        if (!token) return;
        setUserLogged(true);
        EventService.subscribe(EventConstants.user.loginChanged, (isLogged) => setUserLogged(isLogged));
        // return EventService.unsubscribe(event);
    }, [])

    return (
        <nav className="relative bg-main">
            <div className="flex container mx-auto p-4">
                {/* <Aside /> */}


                <button
                    onClick={e => ModalFactory.config()}
                    className="flex items-center text-3xl rounded-3xl p-3 mr-2 hover:shadow">
                    <i className="bx bx-cog"></i>
                </button>

                <Link to="/" className="flex items-center">
                    <i className="bx bxs-map-alt text-3xl mr-2" />
                    <h2 className="relative text-2xl">
                        PAKKA
                        <span className="absolute top-0 bg-primary-200 px-2 rounded-2xl text-sm">{process.env.REACT_APP_VERSION}</span>
                    </h2>
                </Link>

                <div className="flex-grow" />
                <div className="flex items-center mr-2">
                    {
                        userLogged ?
                            <User />
                            :
                            <button className="bg-gray-200 text-xl rounded-2xl px-3 py-1 shadow">
                                Login
                            </button>
                        // <GoogleLogin
                        //     clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        //     buttonText="Login"
                        //     className="bg-gray-200"
                        //     style={{height: 'fit-content'}}
                        //     onSuccess={e => EventService.publish(EventConstants.user.googleLogin, e)}
                        //     onFailure={(e) => console.error(e)}
                        //     cookiePolicy={'single_host_origin'}
                        //     render={renderProps => (
                        //         <button onClick={renderProps.onClick}
                        //                 disabled={renderProps.disabled}
                        //                 className="">
                        //             Login
                        //         </button>
                        //     )}
                        // />
                    }
                </div>
                <div className="flex items-center">
                    <div style={{ height: '3em' }}>
                        <PakkaDropdown
                            button={
                                <i className="bx bxs-layer text-3xl" />
                            }
                            contentStyles={{}}
                        >
                            <div className="flex flex-col bg-main rounded">
                                <Link to={'/'} className="hover:bg-primary-300">
                                    <h2 className="p-3">Map</h2>
                                </Link>
                                <Link to={'/users'} className="hover:bg-primary-300">
                                    <h2 className="p-3">Users</h2>
                                </Link>
                                <Link to={'/items'} className="hover:bg-primary-300">
                                    <h2 className="p-3">Items</h2>
                                </Link>
                            </div>
                        </PakkaDropdown>
                    </div>
                </div>
            </div>
        </nav>
    )
}
