import React from 'react';
import Select, { components, createFilter } from 'react-select';

import { LoggerUtil } from '../../../core/utils/logger.util';
import { GetterUtil } from '../../../core/utils/getter.util';
import { ApiClient } from '../../services/api/api-client';
import { withTranslation } from 'react-i18next';

export class PakkaSelectClass extends React.Component<PakkaSelectProps> {

    public state: { value: any, list?: any[], cssVars: { bg: string, bgSelected: string, text: string } };
    public label?: string;
    public name?: string;
    public id: string;

    constructor(props: PakkaSelectProps) {
        super(props);
        this.state = {
            value: props.model && props.name ? GetterUtil.getProp(props.model, props.name) : props.value,
            list: props.list || [],
            cssVars: {
                bg: getComputedStyle(document.documentElement).getPropertyValue('--color-white'),
                bgSelected: getComputedStyle(document.documentElement).getPropertyValue('--color-primary-200'),
                text: getComputedStyle(document.documentElement).getPropertyValue('--color-black'),
            }
        };
        this.label = this.props.label ? this.props.label : this.props.name;
        this.name = this.props.name ? this.props.name : this.props.label;
        this.id = this.props.id ? this.props.id : '_id';
    }

    componentDidMount() {
        if (this.state.value) this.loadElement(this.state.value[this.id] || this.state.value);
    }

    render() {
        const { t }: any = this.props;
        return (
            <>
                <fieldset className={`flex flex-col ${this.props.className}`}>
                    {!this.props.hideLabel && this.label ?
                        <label className="font-bold text-hint text-sm" htmlFor={this.name}>
                            {t('label.' + this.label.split('.').slice(-1))}
                        </label> : ''}
                    <Select
                        isClearable={this.props.clearable}
                        className="shadow rounded"
                        isSearchable={this.props.searcheable}
                        value={this.state.value}
                        options={this.state.list}
                        getOptionValue={option => option[this.id]}
                        getOptionLabel={option => option['name']}
                        onChange={e => {
                            let newValue = this.props.id ? e[this.props.id] : e;
                            this.setState({ value: e });
                            if (this.props.model && this.props.name) GetterUtil.assignProp(this.props.model, this.props.name, newValue);
                            if (this.props.onChange) this.props.onChange(e);
                        }}
                        filterOption={createFilter({ ignoreAccents: false })}
                        components={{ Option: OptimizedOption }}
                        styles={{
                            input: (provided) => ({
                                ...provided,
                                color: this.state.cssVars.text
                            }),
                            menuList: (provided) => ({
                                ...provided,
                                backgroundColor: this.state.cssVars.bg
                            }),
                            option: (provided, { isFocused }) => ({
                                ...provided,
                                color: this.state.cssVars.text,
                                backgroundColor: isFocused ? this.state.cssVars.bgSelected : this.state.cssVars.bg
                            }),
                            singleValue: (provided) => ({
                                ...provided,
                                color: this.state.cssVars.text,
                            }),
                            control: (provided) => ({
                                ...provided,
                                borderColor: this.state.cssVars.text,
                                color: this.state.cssVars.text,
                                backgroundColor: this.state.cssVars.bg,
                                border: 'none',
                                boxShadow: 'none',
                                padding: '2px 0'
                            })
                        }}
                        onFocus={e => {
                            if (!this.state.list || !this.state.list.length) this.loadList();
                        }}
                    />
                </fieldset>
            </>
        )
    }

    private loadList() {
        if (!(ApiClient as any)[this.props.type]) {
            LoggerUtil.error('PakkaSelect', `No list provided and type ${this.props.type} wasn't found on ApiClient`);
            return;
        }
        (ApiClient as any)[this.props.type].get()
            .then((res: any) => {
                this.setState({ list: res.data });
            })
    }

    private loadElement(id: number) {
        if (!(ApiClient as any)[this.props.type]) {
            LoggerUtil.error('PakkaSelect', `No list provided and type ${this.props.type} wasn't found on ApiClient`);
            return;
        }
        (ApiClient as any)[this.props.type].get(`${id}`)
            .then((res: any) => {
                this.setState({ value: res.data });
            })
    }
}

export const PakkaSelect = withTranslation('translation')(PakkaSelectClass);

interface PakkaSelectProps {
    type: any;
    value?: any;
    model?: any;
    id?: string;
    name?: string;
    label?: string;
    hideLabel?: boolean;
    multiple?: boolean;
    list?: any[];

    clearable?: boolean;
    searcheable?: boolean;
    placeholder?: string;

    className?: string;

    onChange?: any;
}

function OptimizedOption(props: any) {
    delete props.innerProps.onMouseMove;
    delete props.innerProps.onMouseOver;
    return <components.Option {...props}>{props.children}</components.Option>;
}