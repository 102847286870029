import { useTranslation } from 'react-i18next';

export function ModalHeader({
    title,
    hasClose = true,
    onClose
}) {

    const { t } = useTranslation();
    return (
        <div className="flex p-3">
            <h2 className="text-2xl flex-grow">{t(title)}</h2>
            {hasClose ?
                <button
                    className="px-1 rounded-3xl hover:bg-primary-400 hover:shadow hover:text-primary-50 transition"
                    onClick={e => onClose()}>
                    <i className="bx bx-x text-2xl"></i>
                </button>
                : ''}
        </div>
    )
}