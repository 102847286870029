import { EventConstants } from "../../../core/objects/constants";
import { LoggerUtil } from "../../../core/utils/logger.util";
import { EventService } from "../event/event.service";

export class ModalService {
    public static modalQueue: {
        callback: any,
        name: string
    }[] = [];

    public static open(modal: any, callback: any, params: any = {}) {
        if (this.modalQueue[0]) {
            LoggerUtil.log('ModalService', `Modal ${this.modalQueue[0].name} was open, ${modal.name} is replacing it`);
            this.close({ status: 'closed' });
        }
        if (!modal) {
            LoggerUtil.error('ModalService', `No modal specified`);
            return;
        }

        this.modalQueue.push({
            callback: callback,
            name: modal.name
        });

        LoggerUtil.log('ModalService', `Modal ${modal.name} open`);
        EventService.publish(EventConstants.modal.open, { component: modal, params });
    }

    public static close(response: any) {
        if (!this.modalQueue[0]) {
            return false;
        }

        let lastModal = this.modalQueue[this.modalQueue.length - 1];

        if (lastModal.callback) {
            (lastModal.callback)(response);
        }

        LoggerUtil.log('ModalService', `Modal ${lastModal.name} closed`);
        EventService.publish(EventConstants.modal.close, { component: lastModal.name });

        this.modalQueue.pop();
        return true;
    }

    public static closeAll() {
        this.modalQueue.forEach(modal => {
            const modalCallbackQueue = modal.callback;
            if (!modalCallbackQueue.length) return;
            while (modalCallbackQueue.length > 0) {
                (modalCallbackQueue.shift())({ status: 'closed' });
            }
        });

        EventService.publish(EventConstants.modal.closeAll, {});

        return true;
    }
}
