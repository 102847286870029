import { LoggerUtil } from '../../../core/utils/logger.util';

export class StorageService {
    public static get(key: string, storage: 'localStorage' | 'sessionStorage' = 'localStorage') {
        let value = storage === 'localStorage' ? localStorage.getItem(key) : sessionStorage.getItem(key);
        try {
            value = value ? JSON.parse(value) : null;
        } catch (e) {
            LoggerUtil.error('StorageService', e);
        }
        return value;
    }

    public static set(key: string, value: any, storage: 'localStorage' | 'sessionStorage' = 'localStorage') {
        storage === 'localStorage' ? localStorage.setItem(key, JSON.stringify(value)) : sessionStorage.setItem(key, JSON.stringify(value));
    }

    public static delete(key: string, storage: 'localStorage' | 'sessionStorage' = 'localStorage') {
        storage === 'localStorage' ? localStorage.removeItem(key) : sessionStorage.removeItem(key);
    }
}