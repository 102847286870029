import { EventConstants } from "../../../core/objects/constants";
import { LoggerUtil } from "../../../core/utils/logger.util";
import { EventService } from "../event/event.service";

export class AsideService {
    public static asideQueue: { callback: any, name: string } | any;

    public static open(aside: any, callback: any, params: any = {}) {
        if (this.asideQueue) {
            LoggerUtil.log('AsideService', `Aside ${this.asideQueue.name} was open, ${aside.name} is replacing it`);
            this.close({ status: 'closed' });
        }
        if (!aside) {
            LoggerUtil.error('AsideService', `No aside specified`);
        } else {
            this.asideQueue = {
                callback: callback,
                name: aside.name
            }
            LoggerUtil.log('AsideService', `Aside ${aside.name} open`);
            EventService.publish(EventConstants.aside.open, { component: aside, params });
        }
    }

    public static close(response: any) {
        if (!this.asideQueue) {
            return false;
        }

        LoggerUtil.log('AsideService', `Aside ${this.asideQueue.name} closed`);
        if (this.asideQueue.callback) {
            (this.asideQueue.callback)(response);
        }
        this.asideQueue = null;
        return true;
    }
}
