import React          from 'react';
import Phaser         from 'phaser';
import { ApiClient }  from '../../services/api/api-client';
import { LoggerUtil } from '../../../core/utils/logger.util';

export class PakkaAreaMap extends React.Component {

    componentDidMount() {
        // this.rt = null;
        // this.layer = null;
        // this.controls = null;
        // this.camera = null;

        this.isFocused = false;

        this.game = new Phaser.Game({
            type: Phaser.AUTO,
            parent: 'phaser-parent',
            pixelArt: true,
            width: document.getElementById('phaser-parent').clientWidth,
            height: 350,
            scene: {
                preload: this.preload,
                create: this.create,
            }
        });

        this.updateMap();
    }

    componentWillUnmount() {
        this.game.destroy(true);
    }

    render() {
        return (
            <>
                <div className="flex justify-center w-full"
                     id="phaser-parent">
                </div>
            </>
        )
    }

    preload() {
        this.load.image('tiles', 'assets/tilemaps/tiles/basic_tiles.png');
    }

    create() {
        this.camera = this.cameras.main;

        this.input.on('pointermove', (p) => {
            if (!p.isDown) return;
            this.camera.scrollX -= (p.x - p.prevPosition.x) / this.camera.zoom;
            this.camera.scrollY -= (p.y - p.prevPosition.y) / this.camera.zoom;
        });

        this.input.on('wheel', (p, objects, dX, dY, dZ) => {
            if (dY > 0) this.camera.zoom -= .1;
            if (dY < 0) this.camera.zoom += .1;

            this.camera.zoom = Math.max(this.camera.zoom, 0.3);
            this.camera.zoom = Math.min(this.camera.zoom, 2);
        });
    }

    update() {
        // console.log('lel')
    }

    updateMap() {
        ApiClient.area.generateMap(this.props.area._id)
            .then(res => {
                // console.log(res);
                this._renderMap(this.props.area, res.data);
            })
            .catch(e => {
                LoggerUtil.error('PakkaAreaMap', e);
            });
    }

    _renderMap(area, areaGenerated) {
        const width = area.generationParams.size;

        // const finalTileArray = [[]];
        // for (let y = 0; y < area.generationParams.size; y++) {
        //     for (let x = 0; x < area.generationParams.size; x++) {
        //         const tileValue = areaGenerated.tileList[(x * area.generationParams.size) + y] === 1 ? 0 : 1;
        //         finalTileArray[y].push(tileValue);
        //     }
        //     finalTileArray.push([]);
        // }

        const finalTileArray = [];
        let y = -1;
        areaGenerated.tileList.forEach((tile, x) => {
            let insertableTile = tile === 1 ? 0 : 1;
            if (x % width === 0) {
                y++;
                finalTileArray.push([]);
            }
            finalTileArray[y].push(insertableTile);
        });
        finalTileArray[areaGenerated.entrance.x][areaGenerated.entrance.y] = 2;

        const gameScene = this.game.scene.scenes[0];
        const map = gameScene.make.tilemap({data: finalTileArray, tileWidth: 16, tileHeight: 16});
        // console.log(this);
        let tiles;
        try {
            tiles = map.addTilesetImage('tiles');
        } catch {
            this.game.scene.restart();
        }

        map.createLayer(0, tiles, 0, 0);
        gameScene.cameras.main.setBounds(0, 0, 16 * width, 16 * width);
    }
}