import './App.css';
import './assets/css/tailwind.css';

import { Router, } from '@reach/router';

import { Aside, Modal, Navbar } from './shared/components';
import { AuthService } from './shared/services';

import { MapView } from './apps/map/map';
import { ItemsView } from './apps/items/items';
import { UsersView } from './apps/users/users.view';
import { Toast } from './shared/components/toast/toast';

require('./shared/services/api/interceptor');

// const AsyncMap = Loadable({
//   loader: () => import('./apps/map/map'),
//   loading: null
// })

function App() {
    return (
        <div className="app">
            <Navbar />
            <Modal />
            <Aside />
            <Router className="flex flex-grow">
                <MapView path="/" />
                <ItemsView path="/items" />
                <UsersView path="/users" />
            </Router>
            <Toast />
        </div>
    );
}

export default App;

new AuthService();