import { EventConstants } from "../../../core/objects/constants";
import { ApiClient } from "../api/api-client";
import { StorageService } from "../storage/storage.service";
import { EventService } from "../event/event.service";

export class AuthService {

    constructor() {
        console.log('AuthService initialized');
        EventService.subscribe(EventConstants.user.googleLogin, this.handleGoogleLogin);
        EventService.subscribe(EventConstants.user.logout, this.handleLogout);
    }

    private handleGoogleLogin(data: any) {
        ApiClient.login.get('', { headers: { Authorization: data.tokenId } })
            .then(res => {
                if (!res || !res.data) return;
                StorageService.set('token', res.data);
                EventService.publish(EventConstants.user.loginChanged, res.data);
            })
            .catch(err => {
                console.error('Error during login', err);
            })
    }

    private handleLogout() {
        StorageService.delete('token');
        EventService.publish(EventConstants.user.loginChanged, false);
    }
}