import React from 'react';
import './pk-tab-group.css';

export class PakkaTabGroup extends React.Component<PakkaTabGroupProps> {

    public state: any = { activeTab: 0 };

    render() {

        const { props: { children }, state: { activeTab } }: any = this;

        return (
            <div className="pk-tab-group">
                <ol className="tab-list flex bg-accent-100">
                    {this.props.children.map((child: any, index: number) => {
                        const { id: label } = child.props;
                        return (
                            <button
                                style={{ outline: 'none' }}
                                className={`p-3 flex-grow border-accent-400 ${index === activeTab ? 'border-b-4' : ''}`}
                                key={label}
                                onClick={e => this.setState({ activeTab: index })}>{label}
                            </button>
                        )
                    })}
                </ol>
                <div className={`pk-tab-content ${this.props.className}`}>
                    {children.map((child: any, index: number) => {
                        if (index !== activeTab) return undefined;
                        return child.props.children;
                    })}
                </div>
            </div>
        )
    }
}

interface PakkaTabGroupProps {
    children: any;
    className?: string;
}