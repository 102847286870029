import { LoggerUtil } from "../../../core/utils/logger.util";

export class EventService {
    public static queue: any = {};

    public static publish(event: string, data: any) {
        let queue = this.queue[event];

        if (typeof queue === 'undefined') {
            LoggerUtil.warn('EventService', `No queue found for event: ${event}`);
            return false;
        }

        for (const i of queue) {
            (i)(data);
        }

        return true;
    }

    public static subscribe(event: string, callback: any) {
        LoggerUtil.log('EventService', `Subscribed to event: ${event}`);
        if (typeof this.queue[event] === 'undefined') {
            this.queue[event] = [];
        }

        this.queue[event].push(callback);
    }

    //  the callback parameter is optional. Without it the whole event will be removed, instead of
    // just one subscibtion. Enough for simple implementations
    public static unsubscribe(event: string, callback: any) {
        let queue = this.queue;

        if (typeof queue[event] !== 'undefined') {
            if (typeof callback === 'undefined') {
                delete queue[event];
            } else {
                this.queue[event] = queue[event].filter(function (sub: any) {
                    return sub !== callback;
                })
            }
        }
    }
}
