import React from "react";
import { GetterUtil } from "../../../core/utils/getter.util";
import { withTranslation } from 'react-i18next';

class PakkaInputClass extends React.Component<PakkaInputProps> {

    public state: any;
    public label?: string;
    public name?: string;

    constructor(props: PakkaInputProps) {
        super(props);
        this.state = { value: props.model && props.name ? GetterUtil.getProp(props.model, props.name) : props.value };
        this.label = this.props.label ? this.props.label : this.props.name;
        this.name = this.props.name ? this.props.name : this.props.label;
    }

    render() {
        const { t }: any = this.props;
        return (
            <fieldset className={`flex flex-col ${this.props.className}`}>
                {!this.props.hideLabel && this.label ?
                    <label className="font-bold text-hint text-sm" htmlFor={this.name}>
                        {t('label.' + this.label)}
                    </label>
                    :
                    ''}
                <input
                    className="bg-white p-2 rounded shadow outline-none"
                    name={this.name}
                    id={this.name}
                    type={this.props.type}
                    value={this.state.value} max={this.props.max} min={this.props.min}
                    onChange={(e) => {
                        this.setState({ value: e.target.value });
                        if (this.props.model && this.name) GetterUtil.assignProp(this.props.model, this.name, e.target.value);
                        if (this.props.onChange) this.props.onChange(e);
                    }} />
            </fieldset>
        )
    }
}

export const PakkaInput = withTranslation('translation')(PakkaInputClass);

interface PakkaInputProps {
    value?: any;
    model?: any;
    name?: string;
    label?: string;
    hideLabel?: boolean;
    type?: 'text' | 'number';
    min?: number;
    max?: number;

    className?: string;

    onChange?: any;
}