import axios from "axios";

export class Crud {
    private resourceUrl: string;

    constructor(resourceUrl: string) {
        this.resourceUrl = resourceUrl;
    }

    public get(path: string = '', options: any = {}) {
        return axios.get(this.resourceUrl + '/' + path, options);
    }

    public post(path: string = '', body: any, options: any = {}) {
        return axios.post(this.resourceUrl + '/' + path, body);
    }

    public put(path: string = '', body: any, options: any = {}) {
        return axios.put(this.resourceUrl + '/' + path, body);
    }
}