export class GetterUtil {
    public static getProp(object: any, prop: string) {
        let propArray = prop.split('.');
        let currentObject = object;
        if (propArray.length === 1) return object[prop];

        propArray.forEach((prop: string, index: number) => {
            if (index === propArray.length - 1) {
                if (!currentObject[prop]) {
                    currentObject[prop] = null;
                }
                currentObject = currentObject[prop];
                return;
            }
            if (!currentObject[prop]) {
                currentObject[prop] = {};
            }
            currentObject = currentObject[prop];
        });
        return currentObject;
    }

    public static assignProp(object: any, prop: string, value: any) {
        let propArray = prop.split('.');
        let currentObject = object;
        if (propArray.length === 1) {
            object[prop] = value;
            return;
        }

        propArray.forEach((prop: string, index: number) => {
            if (index === propArray.length - 1) {
                currentObject[prop] = value;
                return;
            }
            if (!currentObject[prop]) {
                currentObject[prop] = {};
            }
            currentObject = currentObject[prop];
        });
    }
}