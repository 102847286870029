import React from "react";
import { EventConstants } from "../../../core/objects/constants";
import { ModalOpenData, ModalResponse } from "../../../core/objects/interfaces";
import { EventService, ModalService } from "../../services";
import { ModalHeader } from "./modal-header";

import './modal.css';

export class Modal extends React.Component {

    public state: { modalList: ModalOpenData[] } = {
        modalList: []
    }

    componentDidMount() {
        EventService.subscribe(EventConstants.modal.open, (e: any) => this.handleModalOpen(e));
    }

    render() {
        return (
            <div className="pk-modal-container" id="pk-modal-container"
                style={{ display: this.state.modalList.length ? 'flex' : 'none' }}>
                {this.state.modalList.length ?
                    this.state.modalList.map((modal: ModalOpenData, i: number) => {
                        let { title, size } = modal.params;
                        if (!title) title = modal.name;
                        if (!size) size = 'md';
                        return (
                            <div onClick={e => this.handleBackDropClick()}
                                key={i}
                                className="pk-modal-backdrop">
                                <div onClick={e => e.stopPropagation()}
                                    className={`pk-modal-content bg-primary-50 rounded shadow ${size}`}
                                    style={{ height: '600px' }}>
                                    <ModalHeader title={title} onClose={(_: any) => this.handleModalClose(i)} />
                                    <div className="flex-grow p-3">
                                        {modal.component}
                                    </div>
                                </div>
                            </div>

                        )
                    })
                    :
                    <></>}
            </div>
        )
    }

    private handleModalOpen(modalOpenData: ModalOpenData) {
        const clonedComponent = React.createElement(modalOpenData.component, modalOpenData.params);
        const newModalList = this.state.modalList.concat({ component: clonedComponent, params: modalOpenData.params, name: modalOpenData.component.name });
        this.setState({ modalList: newModalList });
    }

    private handleModalClose(index: number) {
        this.closeModal(index);
    }

    private handleBackDropClick() {
        this.closeModal(this.state.modalList.length - 1, { status: 'backdrop', data: undefined });
    }

    private closeModal(index: number, response: ModalResponse = { status: 'close', data: undefined }) {
        const modal = this.state.modalList[index];
        if (!modal) return;

        ModalService.close(response);

        this.state.modalList.splice(index, 1);
        this.setState(this.state);
    }
}