import axios from "axios";
import { StorageService } from "../storage/storage.service";
import { ToastService } from "../toast/toast.service";

axios.defaults.baseURL = 'https://api.pakka.jaludev.com' + '/api/';

axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    const validToken = StorageService.get('token');
    if (validToken) {
        config.headers.authorization = validToken;
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    ToastService.noConnection(true);
    return response;
}, function (error) {
    if (!error.response) {
        ToastService.noConnection();
    }
    return Promise.reject(error);
});