import React, { useEffect, useState } from 'react';
import { PakkaTabGroup }              from '../../../../shared/components';
import { ApiClient }                  from '../../../../shared/services/api/api-client';
import { ParamsTab }                  from './tabs/params-tab.component';
// import { BuildingsTab }               from './tabs/buildings-tab.component';

export function AreaAside({areaId}: any) {

    const [area, setArea]: any = useState();
    // const [loading, setLoading]: any = useState();

    useEffect(() => {
        loadArea();
        // eslint-disable-next-line
    }, []);
    return (
        <PakkaTabGroup className="p-4">
            <div id="params">
                <ParamsTab area={area}
                           onSaveArea={loadArea}/>
            </div>
            <div id="info">info</div>
            <div id="buildings">
                {/* <BuildingsTab buildingList={area.buildingList} /> */}
            </div>
            <div id="villagers">villagers</div>
        </PakkaTabGroup>
    )

    function loadArea() {
        // setLoading(true);
        ApiClient.area.get('' + areaId)
            // .finally(() => setLoading(false))
            .then(res => {
                setArea(res.data);
            });
    }
}