
import { useState } from 'react';
import './pk-dropdown.css';

export function PakkaDropdown({
    children,
    button,

    contentStyles = {}
}: { children: any, button: any, contentStyles: React.CSSProperties }) {

    const [open, setOpen] = useState(false);

    return (
        <div className="pk__dropdown">
            <button className="h-full px-3 hover:shadow outline-none rounded-3xl" onClick={_ => setOpen(!open)}>
                {button}
            </button>
            {
                open ?
                    <>
                        <div className="pk__content bg-primary-100 shadow-md" style={{ ...contentStyles }}>
                            {children}
                        </div>
                        <div className="pk__backdrop" onClick={() => setOpen(false)}></div>
                    </>
                    :
                    <></>
            }
        </div>
    )
}