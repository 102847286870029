import { toast } from 'react-toastify';
import { NoConnectionToast } from './no-connection.toast';

export class ToastService {

    public static notify() {

    }

    public static noConnection(recovered?: boolean) {
        if (recovered) {
            toast.dismiss('noConnection');
        } else {
            toast(
                NoConnectionToast, {
                toastId: 'noConnection',
                autoClose: false,
                closeButton: false,
                closeOnClick: false,
                type: toast.TYPE.ERROR,
                containerId: '2'
            })
        }
    }
}