/* eslint-disable react-hooks/exhaustive-deps */

import { MapContainer, TileLayer, useMapEvents, Marker } from 'react-leaflet';
import { useEffect, useState, Component } from 'react';
import { icon } from 'leaflet';

import 'leaflet/dist/leaflet.css';

import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png'
import iconUrl from 'leaflet/dist/images/marker-icon.png'
import shadowUrl from 'leaflet/dist/images/marker-shadow.png'
import { AreaService, AsideService, EventService, StorageService } from '../../shared/services';
import { EventConstants } from '../../core/objects/constants';
import { AreaAside } from './asides/area/area.aside';

const markerIcon = icon({
    iconRetinaUrl,
    iconUrl,
    shadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
})

export const MapView = (props: any) => {
    const mapState: any = StorageService.get('mapState') || { center: { lat: 51.505, lng: -0.09 }, zoom: 13 };
    const [state] = useState(mapState);

    return (
        <>
            <MapContainer
                center={state.center} zoom={state.zoom}
                style={{ width: '100%', flexGrow: 1 }}
            >
                <MapControl />
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <MapMarkers />
            </MapContainer>
            {/* <pre>{JSON.stringify(state, undefined, 2)}</pre> */}
        </>
    )
}

function MapControl() {
    const mapState: any = StorageService.get('mapState') || {};
    useEffect(() => {
        if (mapState && mapState.center) AreaService.scanLatLng({ lat: mapState.center.lat, lng: mapState.center.lng });
        // eslint-disable-next-line
    }, []);

    const map = useMapEvents({
        load: () => {
        },
        dragend: (e) => {
            mapState.center = map.getCenter();
            StorageService.set('mapState', mapState);
            AreaService.scanLatLng({ lat: mapState.center.lat, lng: mapState.center.lng });
        },
        zoomend: () => {
            mapState.zoom = map.getZoom();
            mapState.center = map.getCenter();
            StorageService.set('mapState', mapState);
            AreaService.scanLatLng({ lat: mapState.center.lat, lng: mapState.center.lng });
        }
    });
    return null;
}

export class MapMarkers extends Component {

    public state: any = {
        areas: []
    }

    componentDidMount() {
        EventService.subscribe(EventConstants.map.addAreas, ((newAreas: any[]) => {
            const newAreaList = this.state.areas.concat(newAreas);
            this.setState({ areas: newAreaList });
        }));
    }

    render() {
        return (
            this.state.areas.map((area: any) => {
                return (
                    <Marker
                        icon={markerIcon}
                        key={area._id}
                        position={[area.loc.coordinates[1], area.loc.coordinates[0]]}
                        eventHandlers={{
                            click: (e) => {
                                AsideService.open(AreaAside, null, { areaId: area._id })
                            }
                        }}>
                    </Marker>
                )
            })
        )
    }
}