import React from 'react';
import { PakkaAreaMap, PakkaInput, PakkaSelect } from '../../../../../shared/components';
import { ApiClient } from '../../../../../shared/services/api/api-client';

export class ParamsTab extends React.Component<ParamsTabProps> {

    public child: any;

    constructor(props: any) {
        super(props);
        this.child = React.createRef();
    }

    render() {
        return (
            <div className="flex flex-wrap flex-grow">
                {this.props.area ?
                    <>
                        <div className="flex flex-wrap mb-2" style={{ height: 'fit-content' }}>
                            <PakkaInput className="w-full mb-4"
                                model={this.props.area}
                                name="seed"
                            />
                            <PakkaSelect className="w-full mb-4"
                                model={this.props.area}
                                id="_id"
                                type="areaType"
                                name="generationParams.areaType"
                            />
                            <PakkaInput className="w-6/12 mb-4"
                                model={this.props.area}
                                label="size"
                                name="generationParams.size"
                            />
                            <PakkaInput className="w-6/12 mb-4"
                                model={this.props.area}
                                label="freeSpacePercentage"
                                name="generationParams.crumbledPercentage"
                            />
                            <PakkaAreaMap ref={this.child} area={this.props.area} />
                        </div>
                    </>
                    : <></>}
                <button
                    className="sticky bottom-0 bg-secondary-300 font-bold h-12 p-2 rounded w-full"
                    onClick={e => this.saveArea()}>
                    Save and rebuild
                </button>
            </div>
        )
    }


    saveArea() {
        ApiClient.area.put(`${this.props.area._id}`, this.props.area)
            .then(res => {
                this.child.current.updateMap();
                if (this.props.onSaveArea) this.props.onSaveArea();
            })
            .catch(res => {
                console.error(res);
            })
    }
}

interface ParamsTabProps {
    area: any;

    onSaveArea?: any;
}