import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

import { JwtUtil } from '../../../core/utils/jwt';
import { PakkaDropdown } from '../pk-dropdown/pk-dropdown';
import { EventService, StorageService } from '../../services';
import { EventConstants } from '../../../core/objects/constants';

export function User() {
    const token = StorageService.get('token');
    if (token) {
        const user = JwtUtil.decode(token);
        const currentTime = Date.now() / 1000;
        if (user.exp < currentTime) {
            // Token expirado
            console.log('Token expirado');
            EventService.publish(EventConstants.user.logout, {});
        }
        return (
            <div style={{ height: '3em' }}>
                <PakkaDropdown
                    button={<FontAwesomeIcon icon={faUserCircle} />}
                    contentStyles={{}}
                >
                    <div className="flex flex-col bg-gray-700 p-3 rounded">
                        <span>{user.username}</span>
                        <button className="" onClick={_ => {
                            EventService.publish(EventConstants.user.logout, {})
                        }}>
                            Log out
                        </button>
                    </div>
                </PakkaDropdown>
            </div>

        )
    }
    return (
        <>
        </>
    )
}