export const EventConstants = {
    user: {
        googleLogin: 'user.googleLogin',
        loginChanged: 'user.loginChanged',
        logout: 'user.logout',
    },
    map: {
        addAreas: 'map.addAreas',
        removeAreas: 'map.removeAreas'
    },
    modal: {
        open: 'modal.open',
        close: 'modal.close',
        closeAll: 'modal.closeAll'
    },
    aside: {
        open: 'aside.open',
        close: 'aside.close',
        closeAll: 'aside.closeAll'
    }
}