import { ConfigModal } from "shared/components/modal/general/config/config.modal";
import { ModalService } from "./modal.service";

export class ModalFactory {

    /**
     * Open a custom modal
     * @param modal Modal component
     * @param params Modal params
     * @param cb Callback executed once the modal closes
     */
    public static custom(modal: any, params: any, cb: any) {
        ModalService.open(modal, cb, params);
    }

    public static config(params: any, cb: any) {
        let defaultParams = { size: 'md', title: 'label.config' };
        params = { ...params, ...defaultParams };
        ModalService.open(ConfigModal, cb, params);
    }
}
