import dayjs from "dayjs";

export class LoggerUtil {

    public static log(module: string, message: string) {
        return console.log(`[${dayjs().format('HH:mm:ss')}] ${module}: ${message}`);
    }

    public static trace(module: string, message: string) {
        console.trace(`[${dayjs().format('HH:mm:ss')}] ${module}: ${message}`);
    }

    public static warn(module: string, message: string) {
        console.warn(`[${dayjs().format('HH:mm:ss')}] ${module}: ${message}`);
    }

    public static error(module: string, error: any) {
        console.error(`[${dayjs().format('HH:mm:ss')}] ${module}`, error);
    }
}